import { COLORS } from "@asayinc/component-library";
import { Emojis } from "./emojis";

export const cardProps = {
  elevation: 5,
  sx: {
    borderRadius: 2,
    p: 8,
    backgroundColor: COLORS.WHITE,
    "&:hover": {
      backgroundColor: COLORS.WHITE,
    },
  },
};

export const NO_GREETING = "No greeting";
export const greetingsOptions = [
  { label: "Hi {{first_name}},", value: "Hi {{first_name}}," },
  { label: "Dear shareholders,", value: "Dear shareholders," },
  { label: NO_GREETING, value: NO_GREETING },
];

export const TOP_SHAREHOLDER_LABEL_MAPPING = {
  "50": "Top 50",
  "100": "Top 100",
  "500": "Top 500",
  p99: "Top 1%",
  p90: "Top 10%",
  p75: "Top 25%",
};

export const FORM_FIELDS = {
  quickReplies: {
    name: "quickReplies",
    textData: {
      name: "quickReplies.textData",
      options: "quickReplies.textData.options",
      prompt: "quickReplies.textData.prompt",
    },
    emojiData: {
      name: "quickReplies.emojiData",
      options: "quickReplies.emojiData.options",
      prompt: "quickReplies.emojiData.prompt",
    },
    type: "quickReplies.type",
  },
};

export const VALUE_PAIRS = [
  ["qasParticipated", "qasParticipatedCount"],
  ["messagesReceived", "messagesReceivedCount"],
  ["messagesLinksClicked", "messagesLinksClickedRate"],
  ["messagesOpened", "messagesOpenedRate"],
  ["messagesReplied", "messagesRepliedRate"],
];

export const EMOJI_LIST = [
  Emojis.ThumbsUp,
  Emojis.ThumbsDown,
  Emojis.ClappingHands,
  Emojis.RedHeart,
  Emojis.GrinningFace,
  Emojis.ThinkingFace,
  Emojis.CryingFace,
  Emojis.PoutingFace,
];

// used in filter chips as well as wherever a list of selected filters is displayed
export const FILTER_MESSAGING_MAP = {
  topShareholders: {
    chipStartWord: "Top",
    chipEndWord: "shareholder",
  },
  sharesOwned: {
    chipStartWord: "Owns",
    chipEndWord: "share",
  },
  location: {
    chipStartWord: "Located in",
    chipEndWord: "",
  },
  messagesOpened: {
    chipStartWord: "Opened",
    chipEndWord: "of message",
  },
  messagesOpenedRate: {
    chipStartWord: "Opened",
    chipEndWord: "of message",
    isPercent: true,
  },
  messagesLinksClicked: {
    chipStartWord: "Clicked links in",
    chipEndWord: "of message",
  },
  messagesLinksClickedRate: {
    chipStartWord: "Clicked links in",
    chipEndWord: "of message",
    isPercent: true,
  },
  messagesReceived: {
    chipStartWord: "Received",
    chipEndWord: "message",
  },
  messagesReceivedCount: {
    chipStartWord: "Received",
    chipEndWord: "message",
  },
  messagesReplied: {
    chipStartWord: "Replied to",
    chipEndWord: "of message",
  },
  messagesRepliedRate: {
    chipStartWord: "Replied to",
    chipEndWord: "of message",
    isPercent: true,
  },
  qasParticipated: {
    chipStartWord: "Participated in",
    chipEndWord: "Q&A",
  },
  qasParticipatedCount: {
    chipStartWord: "Participated in",
    chipEndWord: "Q&A",
  },
};

export const EMOJI_FONT_FAMILY =
  '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"';
