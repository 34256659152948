import { useForm, FormProvider } from "react-hook-form";
import { Stack } from "@mui/material";
import { RangeFilter, DateFilter } from "../../../../../components/Common";
import { URL_PARAMS } from "../../../../../constants";

interface IProps {
  searchParams: URLSearchParams;
  setSearchParams: (params: URLSearchParams) => void;
}

const FilterList = ({ searchParams, setSearchParams }: IProps): JSX.Element => {
  const methods = useForm({ criteriaMode: "all" });

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      gap={4}
      mt={2}
      width="100%"
    >
      <FormProvider {...methods}>
        <RangeFilter
          stateSearchParams={searchParams}
          stateSetSearchParams={setSearchParams}
          fieldName="sharesRange"
          minParam={URL_PARAMS.sharesOwnedMin}
          maxParam={URL_PARAMS.sharesOwnedMax}
          chipLabel="Shares owned"
        />
        <DateFilter
          stateSearchParams={searchParams}
          stateSetSearchParams={setSearchParams}
          beforeParam={URL_PARAMS.submittedBefore}
          afterParam={URL_PARAMS.submittedAfter}
          fieldNamePrefix="submitted"
          chipLabel="Submitted"
        />
      </FormProvider>
    </Stack>
  );
};

export default FilterList;
