export const columns = [
  "shareholder",
  "shares",
  "asked",
  "upvoted",
  "lastParticipated",
];

export const completedColumns = [
  "shareholder",
  "shares",
  "asked",
  "upvoted",
  "answered",
  "lastParticipated",
];
