import { useCallback, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
// components
import { Box } from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import { TableV2 as Table, pluralize, Text } from "@asayinc/component-library";
import FilterList from "../FilterList";
// utils
import { getNoResultsProps } from "./utils";
import { getColumnData, getRowData } from "./factories";
import { useTableSearchParams } from "../../../../../../../hooks";
import { useSearchFns } from "../../../../../../../hooks/useSearchFns";
// redux/data
import { useGetParticipantsQuery } from "../../../../../../../store/participants";
import {
  initialState,
  useGetEventQuery,
} from "../../../../../../../store/event";
// constants
import { columns, completedColumns } from "./constants";
import {
  ROW_OPTIONS,
  SORT,
  SORT_SYMBOL,
  URL_PARAMS,
  DELETED_USER_ID,
} from "../../../../../../../constants";
// types
import { TableEventData } from "../../../../../../../types/Table";
import { IEventParticipantsData } from "../../../../../../../types/Participants";
import { EventStatusOptions } from "../../../../../../../types/Events";
import { track } from "../../../../../../../analytics";
import { useGetSettingsQuery } from "../../../../../../../store/settings";
import { ISettings } from "../../../../../../../store/settings/types";
import { useSuccessErrorSnacks } from "../../../../../../../hooks/useSuccessErrorSnacks";

interface IProps {
  eventTitle: string;
}

const ParticipantsTable = ({ eventTitle }: IProps): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { eventSlug } = useParams() as { eventSlug: string };
  const drawerShareholderId = searchParams.get(URL_PARAMS.sid);

  // settings
  const { data: settings = {} } = useGetSettingsQuery();
  const { name: companyName } = settings as ISettings;
  // event
  const { data: eventData = initialState } = useGetEventQuery(eventSlug);

  // tracking values for sort
  const trackingData = useMemo(
    () => ({
      name: "Participants Sorted",
      client: companyName,
      eventStatus: eventData.status,
      qaEvent: eventData.slug,
    }),
    [companyName, eventData]
  );
  const {
    paramObj,
    limit,
    page,
    getSort,
    sortFn,
    handlePageChange,
    handleRowsChange,
  } = useTableSearchParams({
    defaultOrdering: `${SORT_SYMBOL.desc}${SORT.lastParticipated}`,
    trackingData,
  });

  const searchFns = useSearchFns(URL_PARAMS.search);

  // show answered column for completed events
  const isEventCompleted = eventData?.status === EventStatusOptions.COMPLETED;
  const eventColumns = useMemo(
    () => (isEventCompleted ? completedColumns : columns),
    [isEventCompleted]
  );

  const apiParams = {
    search: paramObj.search,
    limit: paramObj.limit,
    offset: String((parseInt(page) - 1) * parseInt(limit)),
    ordering: paramObj.ordering,
    shares_owned_min: paramObj.sharesOwnedMin,
    shares_owned_max: paramObj.sharesOwnedMax,
    questions_asked_min: paramObj.questionsAskedMin,
    questions_asked_max: paramObj.questionsAskedMax,
    questions_answered_min: paramObj.questionsAnsweredMin,
    questions_answered_max: paramObj.questionsAnsweredMax,
    questions_upvoted_min: paramObj.questionsUpvotedMin,
    questions_upvoted_max: paramObj.questionsUpvotedMax,
    last_participated_before: paramObj.lastParticipatedBefore,
    last_participated_after: paramObj.lastParticipatedAfter,
  };

  const { data, isLoading, isFetching, isError } = useGetParticipantsQuery({
    eventSlug,
    params: apiParams,
  });

  const particpantData = data as IEventParticipantsData;
  const count = particpantData?.count || 0;

  /**
   * Display shareholder drawer
   * @param data TableEventData
   */
  const goToShareholder = useCallback(
    (tableData: unknown) => {
      const { id, globalUserId } = tableData as TableEventData;
      if (globalUserId === DELETED_USER_ID) {
        return;
      }
      track({
        name: "Participants Profile Selected",
        client: companyName,
        qaEvent: eventData?.slug,
        eventStatus: eventData?.status,
        listOrder: 0,
        shareholderProfileUrl: `/shareholders/${id}`,
      });
      searchParams.set(URL_PARAMS.sid, id);
      setSearchParams(searchParams, { state: { goBackText: "Q&A" } });
    },
    [eventTitle, searchParams]
  );

  const noResultsData = getNoResultsProps(searchParams);

  const columnData = useMemo(
    () =>
      getColumnData({
        goToShareholder,
        sortFn,
        getSort,
      }),
    [goToShareholder, sortFn, getSort]
  );

  const rowData = getRowData({
    participants: particpantData?.results || [],
    drawerShareholderId,
  });

  const paginateProps = {
    onChangePage: handlePageChange,
    onChangeRows: handleRowsChange,
    count: count,
    page: parseInt(page),
    rowsPerPage: parseInt(limit),
    rowOptions: ROW_OPTIONS,
  };

  // searchbar props leveraging useSearchFns
  const searchBarProps = {
    ...searchFns,
    name: "search",
    placeholder: "Search for a shareholder",
  };

  // toolbar dropdown filter list
  const collapseContent = (
    <FilterList
      companyName={companyName}
      slug={eventData.slug}
      eventStatus={eventData.status}
    />
  );

  const toolbarCollapse = {
    Icon: FilterListIcon,
    collapseContent,
  };

  // show error snackbar on api fail
  useSuccessErrorSnacks({
    errorMsg: "Failed to load participants, please re-load the page.",
    isError,
  });

  const { data: allParticipantsData, isLoading: isAllParticipantsDataLoading } =
    useGetParticipantsQuery({
      eventSlug,
      params: {},
    });

  if (
    !isAllParticipantsDataLoading &&
    (allParticipantsData as IEventParticipantsData)?.count === 0
  ) {
    return (
      <Text
        variant="body3"
        emphasis="medium"
        data-testid="participants-empty-text"
      >
        All shareholders who ask or upvote a question will be displayed here
        after the event begins.
      </Text>
    );
  }

  return (
    <Box>
      <Table
        title={`${count} ${pluralize("Shareholder", "Shareholders", count)}`}
        titleInfoIconTooltip="Includes all shareholders who interacted with your event."
        toolbarInside
        tableMinWidth={1198}
        tableLayout="auto"
        testid="participants-table"
        columns={eventColumns}
        columnData={columnData}
        searchBarProps={searchBarProps}
        toolbarCollapse={toolbarCollapse}
        rows={rowData}
        count={count}
        memoCells
        paginateProps={paginateProps}
        noResultsData={noResultsData}
        isLoading={isLoading}
        isFetching={isFetching}
      />
    </Box>
  );
};

export default ParticipantsTable;
