import { useForm, FormProvider } from "react-hook-form";
// components
import { Stack } from "@mui/material";
import {
  DateFilter,
  RangeFilter,
} from "../../../../../../../components/Common";
import { URL_PARAMS } from "../../../../../../../constants";
import { useTracking } from "./useTracking";

interface IProps {
  companyName: string;
  slug: string;
  eventStatus: string;
}

const FilterList = ({
  companyName,
  slug,
  eventStatus,
}: IProps): JSX.Element => {
  useTracking({ companyName, slug, eventStatus });
  const methods = useForm({ criteriaMode: "all" });

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      gap={4}
      mt={2}
      width="100%"
    >
      <FormProvider {...methods}>
        <RangeFilter
          fieldName="sharesRange"
          minParam={URL_PARAMS.sharesOwnedMin}
          maxParam={URL_PARAMS.sharesOwnedMax}
          chipLabel="Shares owned"
        />
        <RangeFilter
          fieldName="asked"
          minParam={URL_PARAMS.questionsAskedMin}
          maxParam={URL_PARAMS.questionsAskedMax}
          chipLabel="Asked"
        />
        <RangeFilter
          fieldName="upvoted"
          minParam={URL_PARAMS.questionsUpvotedMin}
          maxParam={URL_PARAMS.questionsUpvotedMax}
          chipLabel="Upvoted"
        />
        <RangeFilter
          fieldName="answered"
          minParam={URL_PARAMS.questionsAnsweredMin}
          maxParam={URL_PARAMS.questionsAnsweredMax}
          chipLabel="Answered"
        />
        <DateFilter
          beforeParam={URL_PARAMS.lastParticipatedBefore}
          afterParam={URL_PARAMS.lastParticipatedAfter}
          fieldNamePrefix="participated"
          chipLabel="Last participated"
        />
      </FormProvider>
    </Stack>
  );
};

export default FilterList;
