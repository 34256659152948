import { useCallback, useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
// components
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box } from "@mui/material";
import {
  TableV2 as Table,
  pluralize,
  nFormatter,
} from "@asayinc/component-library";
import { FilterList } from "./components";
// constants
import { ROW_OPTIONS, URL_PARAMS } from "../../../../constants";
// redux / data
import { useGetMessageRecipientsQuery } from "../../../../store/messageLists";
import {
  IMessageOverviewAPIResponse,
  useGetMessageOverviewQuery,
} from "../../../../store/messageStats";
import { useSuccessErrorSnacks } from "../../../../hooks/useSuccessErrorSnacks";
import { useGetSettingsQuery } from "../../../../store/settings";
import {
  useGetMessageQuery,
  initialState,
} from "../../../../store/messageCompose";
// types
import { TableEventData } from "../../../../types/Table";
import { ISettings } from "../../../../store/settings/types";
// utils
import { getColumnData, getRowData } from "./factories";
import { useTableSearchParams } from "../../../../hooks/";
import { useSearchFns } from "../../../../hooks/useSearchFns";
import { track } from "../../../../analytics";
import { useMessageParams } from "../../../../hooks";

interface IProps {
  asDrawer?: boolean;
}

const Recipients = ({ asDrawer }: IProps): JSX.Element => {
  const updateUrl = !asDrawer;
  const { messageId, proxyEventId } = useMessageParams(asDrawer);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const searchFns = useSearchFns(URL_PARAMS.search, updateUrl);
  const { data: settings = {} } = useGetSettingsQuery();
  const { name: companyName } = settings as ISettings;
  const { data: messageData = initialState, isError: isMessageError } =
    useGetMessageQuery(messageId);
  const { data: messageOverviewData = {}, isError: isOverviewStatsError } =
    useGetMessageOverviewQuery(messageId);
  const { campaignName, containsLinks, containsReplies } =
    messageOverviewData as IMessageOverviewAPIResponse;

  // tracking values for sort
  const trackingData = useMemo(
    () => ({
      name: "Recipients Sorted",
      client: companyName,
      campaign: campaignName,
      campaignStatus: "sent",
    }),
    [companyName, campaignName]
  );

  const {
    limit,
    page,
    ordering,
    replied,
    getSort,
    paramObj,
    sortFn,
    handlePageChange,
    handleRowsChange,
    // allows for updating url or not updating url and using state params instead
    searchParams: stateSearchParams,
    setSearchParams: stateSetSearchParams,
  } = useTableSearchParams({
    trackingData,
    updateUrl,
  });

  const {
    data: messageRecipientData,
    isFetching,
    isLoading,
  } = useGetMessageRecipientsQuery({
    messageId,
    limit,
    replied,
    offset: (parseInt(page) - 1) * parseInt(limit),
    ordering,
    search: searchFns.searchTerm,
  });

  const recipientCount = messageRecipientData?.count || 0;

  // set pagetitle
  useEffect(() => {
    document.title = `${messageData.campaignName} Recipients | Say Issuer Portal`;
  }, [messageData.campaignName]);

  /**
   * Track data upon landing on this tab
   */
  useEffect(() => {
    if (campaignName && companyName) {
      track({
        name: "Message Campaign Selected",
        client: companyName,
        campaign: campaignName,
        campaignStatus: "sent",
        menuTab: "Recipients",
      });
    }
  }, [companyName, campaignName]);

  // navigate to messages if apis fail
  const getRequestErrorAction = useCallback(() => {
    navigate(proxyEventId ? `/vote/${proxyEventId}/messages` : "/messages");
  }, [proxyEventId]);

  // snackbar for if any apis fail
  useSuccessErrorSnacks({
    errorMsg: "Some data failed to load, please try again.",
    isError: isOverviewStatsError,
    errorAction: getRequestErrorAction || isMessageError,
  });

  const columns = useMemo(() => {
    if (proxyEventId) {
      return ["shareholder", "shares", "voted"];
    } else {
      const columns = ["shareholder", "shares", "opened"];
      if (containsLinks) {
        columns.push("clicked");
      }
      if (containsReplies) {
        columns.push("replied");
      }
      return columns;
    }
  }, [containsLinks, containsReplies]);

  const recipients = messageRecipientData?.results || [];

  /**
   * Route to Shareholder Profile page
   * @param data TableEventData
   */
  const goToShareholder = useCallback(
    (data: unknown) => {
      const { id, idx } = data as TableEventData;
      // idx of shareholder taking into account page theyre on
      const shareholderTotalIdx =
        (parseInt(page) - 1) * parseInt(limit) + (idx || 0) + 1;
      track({
        name: "Recipient Profile Selected",
        client: companyName,
        campaign: campaignName,
        campaignStatus: "sent",
        listOrder: shareholderTotalIdx,
        shareholderProfileUrl: `/shareholders/${id}`,
      });
      searchParams.set(URL_PARAMS.sid, id);
      setSearchParams(searchParams, { state: { goBackText: "Message" } });
    },
    [campaignName, page, limit, searchParams]
  );

  /**
   * Table Data
   */
  const columnData = getColumnData({
    goToShareholder,
    sortFn,
    getSort,
  });

  // row and column data for the table
  const rows = getRowData(recipients);

  /**
   * props for pagination toolbar
   */
  const paginateProps = {
    onChangePage: handlePageChange,
    onChangeRows: handleRowsChange,
    count: recipientCount,
    page: parseInt(page),
    rowsPerPage: parseInt(limit),
    rowOptions: ROW_OPTIONS,
  };

  // searchbar props leveraging useSearchFns
  const searchBarProps = {
    ...searchFns,
    name: "search",
    placeholder: "Search for a recipient",
  };

  // toolbar dropdown filter list
  const collapseContent = (
    <FilterList
      containsReplies={containsReplies}
      campaignName={campaignName}
      companyName={companyName}
      paramObj={paramObj}
      stateSearchParams={stateSearchParams}
      stateSetSearchParams={stateSetSearchParams}
    />
  );

  const toolbarCollapse = {
    Icon: FilterListIcon,
    collapseContent,
  };

  return (
    <Box data-testid="message-recipients-page">
      <Table
        title={`${nFormatter(recipientCount)} ${pluralize(
          "shareholder",
          "shareholders",
          recipientCount
        )}`}
        columns={columns}
        columnData={columnData}
        rows={rows}
        count={recipientCount}
        toolbarInside
        titleVariant="subtitle2"
        searchBarProps={searchBarProps}
        toolbarCollapse={toolbarCollapse}
        tableLayout="auto"
        tableMinWidth={800}
        paginateProps={paginateProps}
        inDrawer={asDrawer}
        isLoading={isLoading}
        isFetching={isFetching}
        loadingRows={10}
        testid="recipients-table"
        memoCells
      />
    </Box>
  );
};

export default Recipients;
