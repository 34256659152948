import { track } from "../../../../analytics";
import { IURL_PARAMS } from "../../../../types/Common";

/**
 * Get no results messaging
 */
export const getNoResultsProps = (
  hasFilterApplied: boolean
): { title: string; subtitle: string } => {
  return {
    title: hasFilterApplied
      ? "No upvotes in this filtered view"
      : "No upvotes yet",
    subtitle: hasFilterApplied
      ? "Sorry, there are no upvotes that match your applied filters."
      : "There have not been any upvotes submitted by participants yet.",
  };
};

/**
 * Track ordering change
 */
export const trackOrderingChange = (
  sortValue: string,
  params: IURL_PARAMS,
  questionId: string
): void => {
  track({
    name: "Upvotes Sort Option Selected",
    searchApplied: Boolean(params.search),
    questionId: questionId,
    sortSelected: sortValue,
    sortingApplied: Boolean(sortValue),
    userTypeFilterApplied: Boolean(params.investor_type),
  });
};
