import {
  ColumnType,
  DATE_FORMAT,
  getInitials,
  nFormatter,
  Row,
} from "@asayinc/component-library";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { SORT } from "../../../../constants";
import { DELETED_USER_ID } from "../../../../constants/settings";
import { IShareholderUpvote } from "../../../../types/QuestionUpvotes";

dayjs.extend(utc);

interface ColumnDataProps {
  goToShareholder: (data: unknown) => void;
  getSort: (value: string) => "desc" | "asc" | undefined;
  sortFn: (data: unknown) => void;
  asDrawer?: boolean;
}

/**
 * Column data for table
 */
export const getColumnData = ({
  goToShareholder,
  getSort,
  sortFn,
  asDrawer,
}: ColumnDataProps): {
  shareholder: ColumnType;
  sharesOwnedAtUpvote: ColumnType;
  upvotedAt: ColumnType;
} => ({
  shareholder: {
    title: "Shareholder",
    sort: getSort(SORT.shareholder),
    sortFn,
    style: "user",
    width: "440px",
    clickHandler: goToShareholder,
    align: "left",
  },
  sharesOwnedAtUpvote: {
    title: "Shares owned",
    sort: getSort(SORT.sharesOwnedAtUpvote),
    sortFn,
    style: "text",
    width: "440px",
    clickHandler: goToShareholder,
    align: "left",
  },
  upvotedAt: {
    title: "Submitted",
    sort: getSort(SORT.upvotedAt),
    sortFn,
    style: "text",
    width: asDrawer ? "200px" : "440px",
    clickHandler: goToShareholder,
    align: "left",
  },
});

interface RowDataProps {
  shareholderUpvotes: IShareholderUpvote[];
}

/**
 * Each rows specific data for table
 */
export const getRowData = ({ shareholderUpvotes }: RowDataProps): Row[] =>
  shareholderUpvotes.map((upvote, idx) =>
    createRowData({
      shareholder: upvote.name,
      profileColor: upvote.profileColor,
      sharesOwnedAtUpvote: nFormatter(upvote.sharesOwnedAtUpvote),
      upvotedAt: dayjs(upvote.upvoteCreated).format(DATE_FORMAT),
      id: upvote.globalUserId,
      data: {
        id: upvote.globalShareholderId,
        idx,
      },
    })
  );

export interface CreateUpvoteQuestionRowData {
  shareholder: string;
  sharesOwnedAtUpvote: string;
  upvotedAt: string;
  profileColor: string;
  id: string;
  data: {
    id: string;
    idx: number;
  };
}

/**
 * factory for generating row data
 * @param rowData key for each column
 * @param data object returned with callback functions
 * @param id id of the row
 */

export const createRowData = ({
  shareholder,
  sharesOwnedAtUpvote,
  profileColor,
  upvotedAt,
  id,
  data,
}: CreateUpvoteQuestionRowData): Row => ({
  rowData: {
    shareholder: {
      userCellData: {
        isDeletedUser: id === DELETED_USER_ID,
        userName: shareholder,
        initials: getInitials(shareholder),
        profileColor: profileColor,
      },
    },
    sharesOwnedAtUpvote: { content: sharesOwnedAtUpvote },
    upvotedAt: { content: upvotedAt },
  },
  data,
  id,
});
