import {
  nFormatter,
  pluralize,
  TableV2 as Table,
} from "@asayinc/component-library";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useCallback, useMemo } from "react";
// constants
import {
  ROW_OPTIONS,
  SORT,
  URL_PARAMS,
  DELETED_USER_ID,
} from "../../../../constants";
import { COLUMNS } from "./constants";
// hooks
import { useGetQuestionQuery } from "../../../../store/question";
import { useTableSearchParams } from "../../../../hooks";
import { useSearchFns } from "../../../../hooks/useSearchFns";
import { useGetShareholderUpvotesQuery } from "../../../../store/shareholderUpvotes";
// factories/utils
import { getColumnData, getRowData } from "./factories";
import { getNoResultsProps, trackOrderingChange } from "./utils";
// types
import { IShareholderUpvotesData } from "../../../../types/QuestionUpvotes";
// components
import FilterList from "./FilterList";
import { skipToken } from "@reduxjs/toolkit/dist/query";

interface IProps {
  eventSlug: string;
  questionId: string;
  asDrawer?: boolean;
}

const Upvotes = ({ eventSlug, questionId, asDrawer }: IProps) => {
  const { data: question } = useGetQuestionQuery(
    eventSlug && questionId ? { eventSlug, questionId } : skipToken
  );
  const authorName = question?.authorName;

  const sortCallback = (sortValue: string, searchParams: URLSearchParams) => {
    const paramObj = Object.fromEntries(new URLSearchParams(searchParams));
    trackOrderingChange(sortValue, paramObj, questionId as string);
  };

  // base params needed for table functionality
  const {
    paramObj,
    page,
    limit,
    search,
    getSort,
    sortFn,
    handlePageChange,
    handleRowsChange,
    searchParams,
    setSearchParams,
  } = useTableSearchParams({
    defaultOrdering: SORT.upvotedAt,
    sortCallback,
    defaultAgg: "avg",
    updateUrl: !asDrawer,
  });

  const searchFns = useSearchFns(URL_PARAMS.search, !asDrawer);
  const apiParams = {
    offset: String((parseInt(page) - 1) * parseInt(limit)),
    search: searchFns.searchTerm,
    limit,
    ordering: paramObj.ordering,
    shares_owned_min: paramObj.sharesOwnedMin,
    shares_owned_max: paramObj.sharesOwnedMax,
    submitted_before: paramObj.submittedBefore,
    submitted_after: paramObj.submittedAfter,
  };

  const defaultIds = useMemo(() => [], []);
  const {
    data = {
      ids: defaultIds,
      results: [],
      count: 0,
    },
    isFetching,
    isLoading,
  } = useGetShareholderUpvotesQuery(
    questionId
      ? {
          questionId: questionId as string,
          params: apiParams,
        }
      : skipToken
  );

  const { results, count } = data as IShareholderUpvotesData;

  /**
   * navigate to shareholder that has been selected
   */
  const goToShareholder = useCallback(
    (data: unknown) => {
      const { id } = data as { id: string };
      if (id === DELETED_USER_ID) {
        return;
      }
      searchParams.set(URL_PARAMS.sid, id);
      setSearchParams(searchParams, { state: { goBackText: "Question" } });
    },
    [authorName, searchParams]
  );

  // props for pagination toolbar
  const paginateProps = {
    onChangePage: handlePageChange,
    onChangeRows: handleRowsChange,
    count: count,
    page: parseInt(page),
    rowsPerPage: parseInt(limit),
    rowOptions: ROW_OPTIONS,
  };

  // props for when there are no results
  const noResultsData = useMemo(() => getNoResultsProps(!!search), [search]);

  // column table props
  const columnData = useMemo(
    () => getColumnData({ goToShareholder, sortFn, getSort, asDrawer }),
    [goToShareholder, sortFn, getSort, asDrawer]
  );

  // row table props
  const rowData = getRowData({
    shareholderUpvotes: results,
  });

  // searchbar props leveraging useSearchFns
  const searchBarProps = {
    ...searchFns,
    name: "search",
    placeholder: "Search for a shareholder",
  };

  // toolbar dropdown filter list
  const collapseContent = (
    <FilterList searchParams={searchParams} setSearchParams={setSearchParams} />
  );

  const toolbarCollapse = {
    Icon: FilterListIcon,
    collapseContent,
  };

  return (
    <div data-testid="page-upvotes">
      <Table
        isLoading={isLoading}
        isFetching={isFetching}
        rows={rowData}
        toolbarInside
        inDrawer={asDrawer}
        memoCells
        columnData={columnData}
        tableMinWidth={asDrawer ? 486 : 700}
        searchBarProps={searchBarProps}
        toolbarCollapse={toolbarCollapse}
        testid="upvotes-table"
        titleVariant="subtitle2"
        tableLayout="auto"
        title={`${nFormatter(count)} ${pluralize("Upvote", "Upvotes", count)}`}
        columns={COLUMNS}
        paginateProps={paginateProps}
        count={count}
        noResultsData={noResultsData}
      />
    </div>
  );
};

export default Upvotes;
