import { NextPrev } from "../../types/Common";
import { MessageStatus } from "../../types/Messages";
import { IBaseMessageStats } from "../messageStats";
import { IComposeMessageContent, IFilterMap } from "../messageCompose";

export enum MessageType {
  SM = "shareholder_message",
  ProxySM = "vote_solicitation",
}

// assuming this will expand, can just move last Edited into all 3 if its just needed for this later

export interface IBaseMessageItem {
  status:
    | MessageStatus.Draft
    | MessageStatus.Scheduled
    | MessageStatus.PendingApproval
    | MessageStatus.Sending
    | MessageStatus.Sent
    | MessageStatus.Attached
    | MessageStatus.Attaching
    | MessageStatus.Fetched
    | MessageStatus.Fetching
    | MessageStatus.ReadyForFetching
    | MessageStatus.RefreshingIndex;
  scheduledSendAt?: string;
  id: string;
  campaignName: string;
  pushNotificationTemplateId?: number;
  lastEdited: string;
  estimatedReach: number;
  scheduledSendAtTimezone?: string;
  type: MessageType;
  proxyEventId?: string;
  estimatedSendCompletion: string | null;
}

export interface IMessageListItem extends IBaseMessageItem {
  stats: IBaseMessageStats;
}

export interface ISentMessage extends Omit<IMessageListItem, "status"> {
  status:
    | MessageStatus.Sending
    | MessageStatus.Sent
    | MessageStatus.Attached
    | MessageStatus.Attaching
    | MessageStatus.Fetched
    | MessageStatus.Fetching
    | MessageStatus.ReadyForFetching
    | MessageStatus.RefreshingIndex;
  htmlPreview?: string;
  content?: IComposeMessageContent;
  filterMap?: IFilterMap;
}

export interface IMessagesAPIResponse {
  count: number;
  next: NextPrev;
  previous: NextPrev;
  results: IMessageListItem[];
}

export interface IMessageClickStats {
  id: number;
  count: number;
  totalClicks: number;
  sharesOwned: number;
  link: string;
  linkDescription: string;
  shareOfTotalClicks: number;
}

export interface IMessageReplyStats {
  id: number;
  count: number;
  sharesOwned: number;
  label: string | null;
  value: string;
  totalReplies: number;
  shareOfTotalResponses: number;
}

interface LinkClicked {
  text: string;
  url: string;
  id: number;
}

export interface IRepliedWith {
  id: number;
  label: string;
  type: "text" | "emoji";
  value: string;
}

export interface IMessageRecipient {
  globalShareholderId: string;
  name: string;
  profileColor: string;
  investorType: string;
  sharesOwned: number;
  voted: boolean;
  messageOpened: string;
  repliedWith: IRepliedWith;
  linksClickedCount: number;
  linksClicked: LinkClicked[];
}

export interface IMessageRecipientsAPIResponse {
  count: number;
  next: NextPrev;
  previous: NextPrev;
  results: IMessageRecipient[];
}

export interface IPushNotificationTemplate {
  id: number;
  title: string;
  text: string;
}
