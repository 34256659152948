import dayjs from "dayjs";
import {
  DATE_FORMAT,
  getInitials,
  nFormatter,
  Row,
} from "@asayinc/component-library";
import { createColumnData } from "../../../../utils";
import { SORT, EMOJI_FONT_FAMILY } from "../../../../constants";
import { IMessageRecipient } from "../../../../store/messageLists";

interface ColumnDataProps {
  goToShareholder?: (data: unknown) => void;
  getSort: (value: string) => "desc" | "asc" | undefined;
  sortFn: (data: unknown) => void;
}

export const getColumnData = ({
  getSort,
  sortFn,
  goToShareholder,
}: ColumnDataProps) => ({
  shareholder: createColumnData({
    title: "Shareholder",
    clickHandler: goToShareholder,
    tooltip: "Shareholder's name",
    style: "user",
    width: "225px",
    sort: getSort(SORT.shareholder),
    sortFn,
  }),
  shares: createColumnData({
    title: "Shares Owned",
    tooltip: "How many of your company's shares they own",
    style: "text",
    width: "225px",
    sort: getSort(SORT.shares),
    sortFn,
  }),
  voted: createColumnData({
    title: "Voted",
    tooltip: "Has shareholder voted",
    style: "text",
    width: "225px",
    sort: getSort(SORT.voted),
    sortFn,
  }),
  opened: createColumnData({
    title: "Opened",
    tooltip: "",
    style: "text",
    width: "225px",
    sort: getSort(SORT.opened),
    sortFn,
  }),
  clicked: createColumnData({
    title: "Clicked",
    tooltip: "",
    style: "multiTextTooltip",
    width: "225px",
    sort: getSort(SORT.clicked),
    sortFn,
  }),
  replied: createColumnData({
    title: "Replied",
    tooltip: "",
    style: "text",
    width: "225px",
    sort: getSort(SORT.replied),
    sortFn,
  }),
});

const createRowData = (
  {
    name,
    profileColor,
    sharesOwned,
    globalShareholderId,
    messageOpened,
    repliedWith,
    linksClicked,
    voted,
  }: IMessageRecipient,
  idx: number
): Row => ({
  id: globalShareholderId,
  data: {
    id: globalShareholderId,
    idx,
  },
  rowData: {
    shareholder: {
      userCellData: {
        userName: name,
        initials: getInitials(name),
        profileColor: profileColor,
      },
    },
    shares: { content: nFormatter(sharesOwned) },
    opened: {
      content: messageOpened ? dayjs(messageOpened).format(DATE_FORMAT) : "-",
    },
    clicked: {
      multiTextTooltipData: {
        displayValues: linksClicked.map(({ text, url }) => ({
          displayText: text,
          link: url,
        })),
      },
    },
    voted: {
      content: voted ? "Yes" : "No",
    },
    replied: {
      content: repliedWith
        ? repliedWith.type === "emoji"
          ? `<div style='font-family:${EMOJI_FONT_FAMILY}; display:flex; align-items:center'><span style="font-size: 24px">${repliedWith.value}</span><span style="margin-left: 16px">${repliedWith.label}</span></div>`
          : `"${repliedWith.value}"`
        : "-",
      asHTML: true,
    },
  },
});

export const getRowData = (recipients: IMessageRecipient[]) =>
  recipients.map((recipient, idx) => createRowData(recipient, idx));
